'use client';

import { useAtomValue, useUpdateAtom } from 'jotai/utils';

import { isSidebarActiveAtom } from 'Atoms';
import styles from './Header.component.module.css';

import { useHeader } from './UseHeader';
import { usePathname } from 'next/navigation';

export const HeaderWrapper = ({ children }) => {
  const path = usePathname();
  const { isAtTop } = useHeader();

  const isHomepage = path === '/' || path?.includes('/home');

  const isSidebarActive = useAtomValue(isSidebarActiveAtom);

  return (
    <div className={styles.wrapper} data-is-homepage={isHomepage && isAtTop} data-is-sidebar-active={isSidebarActive}>
      {children}
    </div>
  );
};

export const HeadeMenuButton = () => {
  const setIsSidebarActive = useUpdateAtom(isSidebarActiveAtom);

  const toggleSidebar = () => setIsSidebarActive((x) => !x);

  return (
    <div className={styles.menuButton} onClick={toggleSidebar}>
      <i className="fal fa-bars" />
    </div>
  );
};

export const HeaderMenuItem = ({ topLevelLink, children }) => {
  const path = usePathname()!;
  const isCurrentSection = (url) => (url ? new RegExp(url, 'gi').test(path) : false);

  return (
    <div
      className={styles.menuItem}
      data-is-active={isCurrentSection(topLevelLink.url)}
      onClick={(e) => e.target.blur()}
    >
      {children}
    </div>
  );
};

export const HeaderSubmenuItem = ({ secondLevelLink, children }) => {
  const path = usePathname();
  const isCurrentSection = (url) => (url ? new RegExp(url, 'gi').test(path) : false);

  return (
    <div className={styles.subMenuItem} data-is-active={isCurrentSection(secondLevelLink.url)}>
      {children}
    </div>
  );
};
