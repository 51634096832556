'use client';

import { useMemo } from 'react';
import { useWindowScroll } from 'react-use';

export const useHeader = () => {
  const { y } = useWindowScroll();
  const isAtTop = y === 0;

  return useMemo(() => ({ isAtTop }), [isAtTop]);
};
