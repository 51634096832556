'use client';

import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { memo, FC, useState, useEffect } from 'react';
import Link from 'next/link';

import { isSidebarActiveAtom, settingsAtom } from 'Atoms';

import styles from './Sidebar.component.module.css';
import { useRouter } from 'next/router';
import { usePathname } from 'next/navigation';

const SidebarComponent = ({ settings }) => {
  const asPath = usePathname();
  const [selectedItem, setSelectedItem] = useState('');
  const [isActive, setIsActive] = useAtom(isSidebarActiveAtom);

  const toggleSelectedItem = (item) => {
    selectedItem === item ? setSelectedItem('') : setSelectedItem(item);
  };

  const isSelectedItem = (item) => item === selectedItem;
  const hasSubItems = (topLevelLink) => !!topLevelLink.childLinks.length;

  useEffect(() => {
    setIsActive(false);
  }, [asPath]);

  return (
    <>
      <div className={styles.sidebarOverlay} data-is-active={isActive} onClick={() => setIsActive(false)} />

      <nav className={styles.sidebar} data-is-active={isActive}>
        <div className={styles.sidebarLinks}>
          {settings?.headerLinks.map((topLevelLink, i) => {
            if (!hasSubItems(topLevelLink)) {
              return (
                <Link
                  href={topLevelLink.url}
                  key={topLevelLink.label + i}
                  className={styles.sidebarLink}
                  onClick={() => toggleSelectedItem(topLevelLink.label)}
                  prefetch={false}
                >
                  {topLevelLink.label}
                </Link>
              );
            }

            return (
              <div
                className={styles.sidebarLink}
                key={topLevelLink.label}
                onClick={() => toggleSelectedItem(topLevelLink.label)}
                data-is-active={isSelectedItem(topLevelLink.label)}
              >
                {topLevelLink.label} &nbsp;
                <i
                  className={`fas fa-caret-right ${styles.caret}`}
                  data-is-active={isSelectedItem(topLevelLink.label)}
                />
                <div className={styles.subMenuItems} data-is-active={isSelectedItem(topLevelLink.label)}>
                  {topLevelLink.childLinks.map((secondLevelLink, j) => (
                    <Link
                      href={secondLevelLink.url}
                      key={secondLevelLink.label + j}
                      className={styles.subMenuItem}
                      onClick={(e) => e.target.blur()}
                      prefetch={false}
                    >
                      {secondLevelLink.label}
                    </Link>
                  ))}

                  {/* <Link
                  href={topLevelLink.url}
                  key={topLevelLink.label}
                  className={styles.subMenuItem}>
                  {topLevelLink.label}
                </Link> */}
                </div>
              </div>
            );
          })}
        </div>

        {/* <div className={styles.sidebarLink}>OUR SIGNS</div>

        <div className={styles.sidebarLevel} data-is-active={isActive}>
          <div className={styles.sidebarLink}>VINYL</div>
          <div className={styles.sidebarLink}>WAYFINDING</div>
          <div className={styles.sidebarLink}>3D LETTERING</div>
          <div className={styles.sidebarLink}>ILLUMINATED</div>
          <div className={styles.sidebarLink}>NEON</div>
          <div className={styles.sidebarLink}>HAND PAINTED</div>
          <div className={styles.sidebarLink}>ACRYLIC & GLASS</div>
          <div className={styles.sidebarLink}>PLAQUES</div>
          <div className={styles.sidebarLink}>PROJECTING</div>
          <div className={styles.sidebarLink}>PAVEMENT</div>
          <div className={styles.sidebarLink}>SOCIAL DISTANCING</div>
        </div>
        <div className={styles.sidebarLink}>YOUR SPACE</div>
        <div className={styles.sidebarLink}>GALLERY</div>
        <div className={styles.sidebarLink}>PROJECTS</div>
        <div className={styles.sidebarLink}>BLOG</div>
        <div className={styles.sidebarLink}>ABOUT</div>
        <div className={styles.sidebarLink}>GET IN TOUCH</div>
      </div> */}
      </nav>
    </>
  );
};

SidebarComponent.displayName = 'SidebarComponent';

export { SidebarComponent };
