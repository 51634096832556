'use client';

import ReactGUA from 'react-ga';
import ReactGA4 from 'react-ga4';

import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

export const AppLogic = () => {
  const pathname = usePathname()!;

  useEffect(() => {
    ReactGUA.initialize('UA-41269333-1');
    ReactGA4.initialize('G-KWP0NDDT7E', { testMode: false });
  }, []);

  useEffect(() => {
    ReactGUA.pageview(pathname);
  }, [pathname]);

  return null;
};
